<template>
    <grid-view 
        title="Коды стретч-карт"
        filter
        create
        create-title="Сгенерировать коды"
        url-create="/short_code/create"
        store-module="short_code"
        :headers="[
            { text: 'ID', value: 'id', width: 1 },
            { text: 'Дата генерации', value: 'created_at_formatted'},
            { text: 'Начальный номер', value: 'start', width: 1  },
            { text: 'Конечный номер', value: 'end', width: 1  },
            { text: 'Количество кодов', value: 'total', width: 1  },
            { text: 'Время генерации, сек.', value: 'time', width: 1  },
            { text: 'Статус', value: 'status_formatted', width: 1  },
            { text: '', value: 'download', width: 1, sortable: false }
        ]"
        :format="formatItems"
    >
        <template v-slot:buttons>
            <v-btn to="/short_code/assign" :color="$const.color.primary" class="ml-2 white--text">
                <v-icon left dark>
                    mdi-clipboard-text
                </v-icon>
                <span>Назначить ISBN</span>
            </v-btn>

            <v-btn to="/short_code/settings" :color="$const.color.primary" class="ml-2 white--text">
                <v-icon left dark>
                    mdi-cog
                </v-icon>
                <span>Настройки</span>
            </v-btn>
        </template>
    </grid-view>
</template>
<script>
import GridView from '@/components/crud/GridView.vue'
export default {
    name: 'ShortCodeIndex',
    components: { GridView }, 
    data() {
        return {}
    },
    methods: {
        formatItems(items) {
            return _.map(items, (item) => {
                // Active
                _.set(item, 'download', '/api/short-code/download?guid=' + item.guid);

                return item;
            })
        } 
    }
}
</script>