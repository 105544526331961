var render = function render(){var _vm=this,_c=_vm._self._c;return _c('grid-view',{attrs:{"title":"Коды стретч-карт","filter":"","create":"","create-title":"Сгенерировать коды","url-create":"/short_code/create","store-module":"short_code","headers":[
        { text: 'ID', value: 'id', width: 1 },
        { text: 'Дата генерации', value: 'created_at_formatted'},
        { text: 'Начальный номер', value: 'start', width: 1  },
        { text: 'Конечный номер', value: 'end', width: 1  },
        { text: 'Количество кодов', value: 'total', width: 1  },
        { text: 'Время генерации, сек.', value: 'time', width: 1  },
        { text: 'Статус', value: 'status_formatted', width: 1  },
        { text: '', value: 'download', width: 1, sortable: false }
    ],"format":_vm.formatItems},scopedSlots:_vm._u([{key:"buttons",fn:function(){return [_c('v-btn',{staticClass:"ml-2 white--text",attrs:{"to":"/short_code/assign","color":_vm.$const.color.primary}},[_c('v-icon',{attrs:{"left":"","dark":""}},[_vm._v(" mdi-clipboard-text ")]),_c('span',[_vm._v("Назначить ISBN")])],1),_c('v-btn',{staticClass:"ml-2 white--text",attrs:{"to":"/short_code/settings","color":_vm.$const.color.primary}},[_c('v-icon',{attrs:{"left":"","dark":""}},[_vm._v(" mdi-cog ")]),_c('span',[_vm._v("Настройки")])],1)]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }